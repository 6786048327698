<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage"></loading>
    <div class="row mt-5">
      <div class="col-3">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniScuole"
            :checked="tutteStagioniScuole"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-3">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_scuole"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaScuole />
      <div class="col-3">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_campionati_promo"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta
          campionati promo
        </button>
      </div>
      <ModalEsportaCampionatiPromo />
      <div class="col-3 text-end">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_campionati_under"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta
          campionati under
        </button>
      </div>
      <ModalEsportaCampionatiUnder />
    </div>
    <br />
    <div class="row justify-content-center">
      <div class="col-md-9 fs-6">
        <div style="margin-left: 19px" v-if="!readOnly">
          <button
            style="border: 1px solid #ffd600"
            type="button"
            class="badge bg-light-warning rounded text-warning me-4 mb-1 p-3"
            :disabled="disableUpdate == 1"
            @click="approvaSelezionati(1)"
          >
            Approva temporaneamente le selezionate
          </button>
          <button
            style="border: 1px solid #9ef99e"
            type="button"
            class="badge bg-light-success rounded text-success me-4 mb-1 p-3"
            :disabled="disableUpdate == 1"
            @click="approvaSelezionati(2)"
          >
            Approva definitivamente le selezionate
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          scuole di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        scuole_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="scuole_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsScuole"
        @current-change="setCurrentPageScuole"
        :checkedAll="checkAll"
        @checkboxClicked="
          checkAll = !checkAll;
          scuole_list.forEach((element) => {
            element.selected = checkAll;
          });
        "
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <!--    //v-if="!data.pagato" -->
          <input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id"
            v-if="data.stato < 3"
            @click="checkAll = false"
        /></template>
        <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
        <template v-slot:cell-societa="{ row: data }"
          ><div class="tab-long">{{ data.societa }}</div></template
        >
        <template v-slot:cell-disciplina="{ row: data }">{{
          data.disciplina
        }}</template>
        <template v-slot:cell-tipologia="{ row: data }">{{
          data.tipologia
        }}</template>
        <template v-slot:cell-categoria="{ row: data }">{{
          data.categoria
        }}</template>
        <template v-slot:cell-data_richiesta="{ row: data }">
          {{ getFormatDate(data.data_richiesta) }}</template
        >
        <template v-slot:cell-data_inizio="{ row: data }">
          {{ getFormatDate(data.data_inizio) }}</template
        >
        <template v-slot:cell-data_fine="{ row: data }">
          {{ getFormatDate(data.data_fine) }}</template
        >
        <template v-slot:cell-stato_scuola="{ row: data }"
          ><h1 v-if="data.stato == 2" class="badge badge-light">
            In approvazione
          </h1>
          <h1 v-if="data.stato == 1" class="badge badge-light-warning">
            Approvata temporaneamente
          </h1>
          <h1 v-if="data.stato == 4" class="badge badge-light-success">
            Approvata definitivamente
          </h1>
          <h1 v-if="data.stato == 3" class="badge badge-light-danger">
            Rifiutata
          </h1></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_edit_scuola"
              type="button"
              v-if="
                data.stato < 3 ||
                (data.stato == 4 && isEnabled('fnEditScuolaApprovata'))
              "
              @click="
                selectedScuolaId = data.id;
                selectedSocietaId = data.id_societa;
                selectedScuolaStato = data.stato;
              "
            >
              <i class="bi bi-pen text-dark fs-4"></i>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="
                data.stato < 3 ||
                (data.stato == 4 && isEnabled('fnEditScuolaApprovata'))
              "
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                v-if="data.stato == 2"
                @click.prevent="approvaSingoli(1, data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Approva temporaneamente
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.stato == 1"
                @click.prevent="approvaSingoli(4, data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Approva definitivamente
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a href="#" @click.prevent="setAzioni('rifiuta', data.id)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rifiuta </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.stato == 2"
                @click.prevent="setAzioni('elimina', data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Elimina richiesta
                      </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <EditScuolaTennis
      :id_societa="selectedSocietaId"
      :id_scuola="selectedScuolaId"
      :stato="selectedScuolaStato"
      @resetids="resetids"
      @getScuoleList="$emit('getScuoleList')"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import EditScuolaTennis from "../../società/scuola-tennis/EditScuolaTennis.vue";
import isEnabled from "@/composables/isEnabled.js";
import {
  richiesteApprove,
  richiesteRefuse,
  richiesteDel,
} from "@/requests/snmScuole";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { useStore } from "vuex";
import ModalEsportaScuole from "./ModalEsportaScuole.vue";
import ModalEsportaCampionatiPromo from "./ModalEsportaCampionatiPromo.vue";
import ModalEsportaCampionatiUnder from "./ModalEsportaCampionatiUnder.vue";

export default {
  name: "table-scuole-tennis",
  components: {
    Datatable,
    EditScuolaTennis,
    Loading,
    ModalEsportaScuole,
    ModalEsportaCampionatiPromo,
    ModalEsportaCampionatiUnder,
  },
  emits: ["getScuoleList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();
    const checkAll = ref(false);
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const selectedScuolaId = ref(null);
    const selectedSocietaId = ref(null);
    const selectedScuolaStato = ref(null);

    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Disciplina",
        key: "disciplina",
      },
      {
        name: "Tipologia",
        key: "tipologia",
      },
      {
        name: "Categoria",
        key: "categoria",
      },
      {
        name: "Data richiesta",
        key: "data_richiesta",
        sortable: false,
      },
      {
        name: "Data inizio",
        key: "data_inizio",
      },
      {
        name: "Data fine",
        key: "data_fine",
        sortable: false,
      },
      {
        name: "Stato",
        key: "stato_scuola",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const disableUpdate = ref(0);

    const isLoading = ref(false);
    const fullPage = ref(true);

    const scuole_list = computed(() =>
      store.getters.getStateFromName("resultsscuole_list")
    );

    const richiesteScuoleApprova = (stato, array_richieste) => {
      disableUpdate.value = 1;
      isLoading.value = true;
      const responseAdd = richiesteApprove(stato, array_richieste);
      responseAdd
        .then((value) => {
          if (value.data.statusCode == 200) {
            alertSuccess("Operazione completata");
            emit("getScuoleList");
          } else {
            const strMsg = ref(
              value.data.message
                ? value.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            alertFailed(strMsg.value);
          }
        })
        .finally(() => {
          isLoading.value = false;
          disableUpdate.value = 0;
        });
    };

    const approvaSelezionati = (value) => {
      let richiesta = [];
      scuole_list.value.forEach((element) => {
        if (element.selected) {
          richiesta.push(element.id);
        }
      });
      let titleAvviso = "Approvazione temporanea";
      let messaggioAvviso =
        "Sicuro di voler approvare temporaneamente le scuole in approvazione selezionate?";
      let stato = 1;
      if (value == 2) {
        titleAvviso = "Approvazione definitiva";
        messaggioAvviso =
          "Sicuro di voler approvare definitivamente le scuole con approvazione temporanea selezionate?";
        stato = 4;
      }
      if (richiesta.length == 0) {
        alertFailed("Seleziona una o più richieste");
      } else {
        Swal.fire({
          title: titleAvviso,
          html: messaggioAvviso,
          showCancelButton: true,
          confirmButtonText: "Conferma",
          cancelButtonText: "Annulla",
        }).then((result) => {
          if (result.isConfirmed) {
            richiesteScuoleApprova(stato, richiesta.join(","));
          }
        });
      }
    };

    const approvaSingoli = (stato, id) => {
      let titleAvviso = "Conferma approvazione temporanea";
      let messaggioAvviso =
        "Sei sicuro di voler approvare temporaneamente la scuola selezionata?";
      if (stato == 4) {
        titleAvviso = "Conferma approvazione definitiva";
        messaggioAvviso =
          "Sei sicuro di voler approvare definitivamente la scuola selezionata?";
      }
      Swal.fire({
        title: titleAvviso,
        html: messaggioAvviso,
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          richiesteScuoleApprova(stato, id);
        }
      });
    };

    const setAzioni = (tipo, id) => {
      let title = "";
      let message = "";
      switch (tipo) {
        case "rifiuta":
          title = "Conferma rifiuto";
          message = "Sei sicuro di voler rifiutare la scuola selezionata?";
          break;
        case "elimina":
          title = "Conferma cancellazione";
          message = "Sei sicuro di voler cancellare la scuola selezionata?";
          break;
      }
      Swal.fire({
        title: title,
        html: message,
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responseUpdate = ref({});
          switch (tipo) {
            case "rifiuta":
              responseUpdate.value = richiesteRefuse(id);
              break;
            case "elimina":
              responseUpdate.value = richiesteDel(id);
              break;
          }
          responseUpdate.value
            .then((value) => {
              if (value.data.statusCode == 200) {
                alertSuccess("Operazione completata");
                emit("getScuoleList");
              } else {
                const strMsg = ref(
                  value.data.message
                    ? value.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                alertFailed(strMsg.value);
              }
            })
            .finally(() => {
              isLoading.value = false;
            });
        }
      });
    };

    const setTutteLeStagioniScuole = () => {
      store.commit("setTutteLeStagioniScuole");
      emit("getScuoleList");
    };

    const setFetchRowsScuole = (e) => {
      store.commit("setFetchRowsScuole", e);
      emit("getScuoleList");
    };
    const setCurrentPageScuole = (page) => {
      store.commit("setCurrentPageScuole", page);
      emit("getScuoleList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnScuole", columnName);
      store.commit("setSortOrderScuole", order);
      emit("getScuoleList");
    };

    const resetids = () => {
      selectedScuolaId.value = null;
      selectedSocietaId.value = null;
      selectedScuolaStato.value = null;
    };

    return {
      getFormatDate,
      tableHeader,
      selectedScuolaId,
      selectedSocietaId,
      selectedScuolaStato,
      approvaSelezionati,
      disableUpdate,
      isLoading,
      fullPage,
      approvaSingoli,
      setAzioni,
      checkAll,
      isEnabled,
      scuole_list,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedscuole_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordscuole_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusscuole_list")
      ),
      tutteStagioniScuole: computed(() => store.getters.tutteStagioniScuole),
      setTutteLeStagioniScuole,
      currentPage: computed(() => store.getters.currentPageScuole),
      rowsToSkip: computed(() => store.getters.rowsToSkipScuole),
      fetchRows: computed(() => store.getters.fetchRowsScuole),
      sortColumn: computed(() => store.getters.sortColumnScuole),
      sortOrder: computed(() => store.getters.sortOrderScuole),
      setFetchRowsScuole,
      setCurrentPageScuole,
      setSortOrderColumn,

      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      resetids,
    };
  },
};
</script>

<style scoped></style>
